main {
	margin: 0 auto;
	max-width: 100%;
  padding: 2rem;
  background-color: #EAE3EA;
  min-height: 70vh;
}

:root {
  --spacing-one: calc(1rem * 0.5);
  --spacing-two: calc(1rem);
  --spacing-five: calc(2.5rem);
}

body {
  margin: 0;
  padding: 0%;
  width: 100%;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: .1em;
}

header {
  background-color: #77C9D4;
  box-shadow: 0px 10px 10px grey!important;
  /*min-height: 15vh;*/
}

.main-page {
  min-height: 100vh;
}

.form-control {
  background-color: #EAE3EA!important;
}

.resume-page {
  height: 100%;
}

a {
  text-decoration: none;
  font-weight: bold;
}

li {
  list-style: none;
}

.inline {
  display: inline;
}

.flex-row {
  display: flex;
  flex-wrap: wrap;
}

.space-between {
  justify-content: space-between;
}

.space-around {
  justify-content: space-around;
}

.flex-center {
  justify-content: center;
}

.flex-end {
  justify-content: flex-end;
}

.mx-1 {
  margin-right: var(--spacing-one);
  margin-left: var(--spacing-one);
}

.mx-2 {
  margin-right: var(--spacing-two);
  margin-left: var(--spacing-two);
}

.my-1 {
  margin-top: var(--spacing-one);
  margin-bottom: var(--spacing-one);
}

.my-2 {
  margin-top: var(--spacing-two);
  margin-bottom: var(--spacing-two);
}

.my-5 {
  margin-top: var(--spacing-five);
  margin-bottom: var(--spacing-five);
}

.px-1 {
  padding-right: var(--spacing-one);
  padding-left: var(--spacing-one);
}

.px-2 {
  padding-right: var(--spacing-two);
  padding-left: var(--spacing-two);
}

.py-1 {
  padding-top: var(--spacing-one);
  padding-bottom: var(--spacing-one);
}

.py-2 {
  padding-top: var(--spacing-two);
  padding-bottom: var(--spacing-two);
}

li p {
  cursor: pointer;
}

.navActive {
  color: #F3A847;
}

.project-photo:hover {
  opacity: 0.7;
}

.card {
  background-color: #B56357!important;
  box-shadow: 5px 5px 5px grey;
}

.project-card {
  width: 25rem;
  height: auto;
}

.image-stack {
  position: relative;
}

.image-stack:hover .overlay {
  bottom: 0;
  height: 100%;
}

.project-photo {
  /*max-width: 24rem;*/
  height: auto;
}

.overlay {
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  background-color: #77C9D4;
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: .5s ease;
}

.live-app-text {
  text-decoration: none;
  color: white;
}

.overlay-text {
  white-space: nowrap; 
  font-size: 20px;
  position: absolute;
  overflow: hidden;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.profile-pic {
  min-width: 300px;
  width: 100%;
  height: auto;
}

/*.bio {
  overflow-wrap: break-word;
}*/

.skill-list {
  line-height: 1;
  margin-top: 1rem;
}

.footer {
  margin: 0 auto;
  max-width: 100%;
  height: 8rem;
  padding: 30px 30px;
  text-align: center;
  background-color: #77C9D4;
  min-height: 15vh;
}

.li-logo {
  width: 200px;
  height: auto;
}

.full-width {
  width: 100%;
}

#contact-form {
  width: 80%;
  background-color: #B56357!important ;
}

.text-center {
  text-align: center;
}

@media (max-width: 708px) {
  .header-element {
    text-align: center;
    margin: auto;
  }

  #about-section {
    flex-wrap: wrap!important;
  }

  .bio {
    width: 100%;
  }

  ul {
    padding-inline-start: 0px;
  }
}